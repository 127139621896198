
import { Component, Vue, Prop } from "vue-property-decorator";
import { dispatchSubmitWithdrawRequest } from "@/store/mlm/actions";
import { dispatchValidateDexnetAddress } from "@/store/mlm/actions";

@Component({})
export default class TransferTokensModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;

  @Prop({
    required: true,
    default: { success: false, amount: 0, last_transaction_dt: null },
  })
  // @ts-ignore
  public readonly tokenInfo: {
    success: boolean;
    amount: number;
    last_transaction_dt: Date | null;
  };

  public withdrawAddress = "";
  public withdrawError: any = "";
  public addressValid: boolean = false;
  public withdrawSuccessfull: boolean = false;
  public loadingState: boolean = false;

  public closeModal() {
    this.$emit("close-modal");
  }

  public async validateWithdrawForm() {
    this.withdrawError =
      this.withdrawAddress === "" ? this.$t("Address cannot be empty") : "";

    if (this.withdrawAddress !== "") {
      let response = await dispatchValidateDexnetAddress(
        this.$store,
        this.withdrawAddress
      );
      if (!response || response.status != 200) {
        this.withdrawError = this.$t("Address is not valid");
        this.addressValid = false;
      } else {
        this.addressValid = true;
      }
    }

    return !this.withdrawError;
  }

  public async handleWithdrawFunds() {
    if(this.loadingState) return;
    this.loadingState = true;
    if (await this.validateWithdrawForm()) {
      const data = {
        amount: this.tokenInfo.amount,
        standart: "BEP20",
        token: "DEXFREEDOM",
        wallet_address: this.withdrawAddress,
        security_code: "",
      };
      let response = await dispatchSubmitWithdrawRequest(this.$store, data);

      if (response) {
        // this.withdrawStep = 2;
        this.$toast.success(
          this.$t(
            "Withdraw request created successfully. We have sent you an email to confirm withdrawal"
          ).toString()
        );
        this.withdrawSuccessfull = true;
        // this.closeModal();
      } else {
        try {
          this.withdrawError = response.security_code
              ? response.security_code[0]
              : "";
          if (response.wallet_address) {
            this.withdrawError = response.wallet_address
                ? response.wallet_address[0]
                : "";
          } else if (response.non_field_errors) {
            this.withdrawError = response.non_field_errors
                ? response.non_field_errors[0]
                : "";
          }
          this.withdrawError = response.amount ? response.amount[0] : "";
          this.$toast.error(this.$t("Error").toString());
        } catch (e) {}
      }
    }
    this.loadingState = false;
  }
}
